import React from "react"
import ReactPlayer from "react-player"

import Carousel from "react-grid-carousel"

import { Container, Row, Col, Card } from "react-bootstrap"

import * as styles from "../../../styles/components/indexPage/resources/videoResources.module.scss"

// React Multi Carousel Breakpoints
const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const videoFiles = [
  {
    url: "https://youtu.be/gAPYdQ5SKiY",
    desc: "Kids dream of being heroes! At Kids & Teens, We employ them! | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/qoLvhitf9rU",
    desc: "Your child's health & safety is our Priority | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/_g4-2eSqERU",
    desc: "It's Flu season…Call your pediatrician's office today | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/R1AihMKEMUU",
    desc: "Getting Children's Vaccinations during the Covid-19 Pandemic || Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/Yo7aYZtCSOY",
    desc: "What to expect at a Meet & Greet at Kids & Teens Medical Group | Dr. Janesri De Silva",
  },
  {
    url: "https://youtu.be/T0FTVD2slhU",
    desc: "Schedule an Appointment with Kids & Teens Today | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/fjlc4OyLbpE",
    desc: "Expecting your First Child? Get to know the First Steps | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/t9sFik15PWw",
    desc: "Dr.Janesri Desilva explains 4 simple steps of scheduling an appointment @ Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/L7kZeTyFfBw",
    desc: "VLOG Post - Covid19 Vaccine Update - Dr Janesri De Silva | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/JJbSelc1q3s",
    desc: "Kids Developmental Milestones - 24 Months - 02 Years | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/SiTjiDWj1ms",
    desc: "Get the best pediatric medical care for your child | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/RyPUn02Yju8",
    desc: "Telehealth Service by Kids & Teens Medical Group",
  },
]

// const VideoResources = () => {
//   return (
//     <div>
//       <Container>
//         <Row>
//           <Carousel
//             sliderClass="align-items-center"
//             autoPlay={false}
//             autoPlaySpeed={5000}
//             draggable={true}
//             responsive={responsive}
//             infinite={true}
//             keyBoardControl={true}
//             swipeable={true}
//             id="video-carousel"
//           >
//             {videoFiles.map((file, i) => (
//               <Col className="m-3">
//                 <Card className={`${styles.card} mx-auto`}>
//                   {/* <Card.Header className={`${styles.header}`}>
//                     {file.title}
//                   </Card.Header> */}
//                   <Card.Body>
//                     <div className="ratio ratio-16x9">
//                       <ReactPlayer
//                         url={file.url}
//                         style={{ maxWidth: "100%", maxHeight: "100%" }}
//                       />
//                     </div>
//                   </Card.Body>
//                   <Card.Footer className={`${styles.footer} py-5`}>
//                     <h4>{file.desc}</h4>
//                   </Card.Footer>
//                 </Card>
//               </Col>
//             ))}
//           </Carousel>
//         </Row>
//       </Container>
//     </div>
//   )
// }

const Gallery = () => {
  return (
    <Carousel cols={3} rows={2} gap={10} loop>
      {videoFiles.map((file, i) => (
        <Carousel.Item>
          <div className="ratio ratio-16x9">
            <ReactPlayer
              url={file.url}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default Gallery
