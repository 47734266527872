import React from "react"
import { Container, Row, Col, Button, Card } from "react-bootstrap"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-react-intl"

import { GatsbyImage } from "gatsby-plugin-image"
import Carousel from "react-multi-carousel"
import { useIntl } from "gatsby-plugin-react-intl"

//styles
import * as styles from "../../styles/components/blog/blog.module.scss"

// React Multi Carousel Breakpoints
const responsive = {
  // largeDesktop: {
  //   breakpoint: { max: 4000, min: 1440 },
  //   items: 3,
  // },
  // desktop: {
  //   breakpoint: { max: 1440, min: 1024 },
  //   items: 2,
  // },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 1,
  // },
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 1024, min: 0 },
    items: 1,
  },
}

const Blog = () => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: date, order: DESC }, limit: 10) {
        edges {
          node {
            slug
            title
            date(formatString: "MMM DD, YYYY")
            image {
              gatsbyImageData(quality: 100, width: 400, height: 200)
            }
            shortdescription
          }
        }
      }
    }
  `)

  const allBlogPosts = query.allContentfulBlogPost.edges.map(edge => ({
    slug: edge.node.slug,
    image: edge.node.image.gatsbyImageData,
    title: edge.node.title,
    date: edge.node.date,
    shortdescription: edge.node.shortdescription,
  }))

  const intl = useIntl()

  return (
    <Container className="my-5">
      <Row className="">
        <Carousel
          sliderClass="align-items-center"
          autoPlay={true}
          autoPlaySpeed={5000}
          draggable={true}
          responsive={responsive}
          infinite={true}
          keyBoardControl={true}
          swipeable={true}
        >
          {allBlogPosts.map((blogPost, idx) => (
            <Col key={idx} className={`${styles.blogPost} m-3 h-100`}>
              <Card className={`${styles.card}`}>
                <GatsbyImage image={blogPost.image} alt={blogPost.title} />

                <Card.Body className="p-lg-5 d-flex flex-column">
                  <h3>
                    <strong>{blogPost.title}</strong>
                  </h3>
                  <p className="mt-1 mb-2 small text-secondary">
                    <strong>{blogPost.date}</strong>
                  </p>
                  <p className={`${styles.text} mb-3`}>
                    {blogPost.shortdescription}
                  </p>
                  <Link
                    to={`/blog/${blogPost.slug}`}
                    className={`py-3 mt-auto `}
                  >
                    <Button variant="book-now-pink" className="py-2 px-5">
                      {intl.formatMessage({ id: "learnMore" })}
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Carousel>
        {/* <div className="text-center">
          <Link to="/blog">
            <Button>{intl.formatMessage({ id: "visitBlog" })}</Button>
          </Link>
        </div> */}
      </Row>
    </Container>
  )
}

export default Blog
