import React from "react"
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap"
import Carousel from "react-grid-carousel"

import * as styles from "../../styles/components/indexPage/resources/videoResources.module.scss"

// React Multi Carousel Breakpoints
const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

// Development Milestones
const resourceFiles = [
  {
    title: "Kids & Teens Medical Group Vaccine Schedule",
    url: "vaccine-schedule.pdf",
  },

  {
    title: "Developmental Milestones - 02 Months ",
    url: "2-months.pdf",
  },
  {
    title: "Developmental Milestones - 04 Months",
    url: "4-months.pdf",
  },
  {
    title: "Developmental Milestones - 06 Months",
    url: "6-months.pdf",
  },
  {
    title: "Developmental Milestones - 12 Months (1 Year)",
    url: "12-months.pdf",
  },
  {
    title: "Developmental Milestones - 24 Months (2 Years)",
    url: "24-months.pdf",
  },
  {
    title: "Sports Physical Policy Letter Document",
    url: "sports.pdf",
  },
]

export default function Resources() {
  return (
    <Container>
      <Row>
        <Carousel cols={3} rows={1} gap={10} loop>
          {resourceFiles.map((file, i) => (
            <Carousel.Item>
              <Col key={i}>
                <Card className={`${styles.card} mx-auto`}>
                  <Card.Header>{file.title}</Card.Header>
                  <Card.Body>
                    <iframe
                      src={`/resources/${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="100%"
                    />
                  </Card.Body>
                  <Card.Footer className={`${styles.footer} py-2 mx-auto`}>
                    <a href={`/resources/${file.url}`} target="_blank">
                      <Button variant="book-now-pink">Download</Button>
                    </a>
                  </Card.Footer>
                </Card>
              </Col>
            </Carousel.Item>
          ))}
        </Carousel>
      </Row>
    </Container>
  )
}
