import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Row, Button, Col } from 'react-bootstrap'
import Blog from '../Homepage/blog'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import { FaRegCheckCircle } from 'react-icons/fa'
import * as styles from '../../styles/components/indexPage/serendibAndArticles.module.scss'

const SerendibAndArticles = () => {
  const intl = useIntl()
  return (
    <div className={styles.serendibAndArticles}>
      <Row>
        <Col xl={9} className='m-0 p-0'>
          <div className={styles.serendibHeader}>
            {intl.formatMessage({ id: 'serendibMainHeader' })}
            <div className={styles.line} />
          </div>
          <Row className='d-flex flex-row'>
            <Col md={5} className={styles.serendibBody}>
              <StaticImage
                src='../../images/serendib-logo-white.png'
                alt='serendib-logo'
                width={100}
                className='mb-3 mb-xl-5'
              />
              <h2 className='display-6'>
                {intl.formatMessage({ id: 'serendibH1' })}
              </h2>
              <h1 className='display-3 fw-bold mt-2'>
                {intl.formatMessage({ id: 'serendibH2' })}
              </h1>
              <h3 className='my-5'>
                {intl.formatMessage({ id: 'serendibDescription' })}
              </h3>

              <h4 className='d-flex'>
                <div>
                  <FaRegCheckCircle
                    color='yellow'
                    className='small me-3'
                    styles={{ width: '10px' }}
                  />
                </div>
                {intl.formatMessage({ id: 'serendibPoint1' })}
              </h4>
              <h4 className='d-flex'>
                <FaRegCheckCircle
                  color='yellow'
                  className='small me-3'
                  styles={{ width: '10px' }}
                />
                {intl.formatMessage({ id: 'serendibPoint2' })}
              </h4>

              <a href='https://www.serendibhealthways.com/' target='_blank'>
                <button className={`mt-3 mt-xl-5 ${styles.regButton}`}>
                  {intl.formatMessage({ id: 'serendibBtn' })}
                </button>
              </a>
            </Col>
            <Col md={7} className='m-0 p-0'>
              <StaticImage
                src='../../images/main-banner.jpg'
                alt='main-banner'
                style={{ height: '100%', width: '100%' }}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={3} className='m-0 p-0'>
          <div className='m-0 text-center'>
            <h3 className={styles.newsArticles}>
              {intl.formatMessage({ id: 'newsArticles' })}
            </h3>

            <Blog />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SerendibAndArticles
