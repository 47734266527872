// extracted by mini-css-extract-plugin
export var blinking = "newtimetable-module--blinking--yQkka";
export var blinking2 = "newtimetable-module--blinking2--zlr1i";
export var btnActive = "newtimetable-module--btnActive--+urVp";
export var button = "newtimetable-module--button--88xh+";
export var buttonBook = "newtimetable-module--buttonBook--V8qN5";
export var container = "newtimetable-module--container--A6ujb";
export var dark = "newtimetable-module--dark--MM72q";
export var darkMobile = "newtimetable-module--darkMobile--z72hG";
export var days = "newtimetable-module--days--W+eeA";
export var footer = "newtimetable-module--footer--FE0a9";
export var green = "newtimetable-module--green--NJjHj";
export var hours = "newtimetable-module--hours--G2jy-";
export var item = "newtimetable-module--item--aXbEO";
export var item2 = "newtimetable-module--item2--yZ7r5";
export var link = "newtimetable-module--link--GuDJ-";
export var location = "newtimetable-module--location--0NoB7";
export var locationList = "newtimetable-module--locationList--iDnLH";
export var mainContent = "newtimetable-module--mainContent--AR0Ie";
export var options = "newtimetable-module--options--kSyLK";
export var row = "newtimetable-module--row--XFgYi";
export var select = "newtimetable-module--select--ErM-g";
export var table = "newtimetable-module--table--KYtV4";
export var tableRow = "newtimetable-module--tableRow--gNdPm";
export var tele = "newtimetable-module--tele--7mIaD";
export var thead = "newtimetable-module--thead--jJl6p";
export var thead2 = "newtimetable-module--thead2--eodpY";
export var thead3 = "newtimetable-module--thead3--V9KQf";