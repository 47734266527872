import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"

//Components
import TechSupport from "../techSupport"
import Divider from "../divider"
import NewTimeTable from "../new-time-table"
import Tags from "./tags"
import AfterHours from "../afterHours"
import WhyKtmgNew from "./why-ktmg-new"

//styles
import { Container, Row, Button, Col } from "react-bootstrap"
import * as styles from "../../styles/components/indexPage/main.module.scss"
import SerendibAndArticles from "./serendibAndArticles"

const Main = () => {
  const intl = useIntl()

  return (
    <>
      <div className={styles.main}>
        <Container
          fluid
          className={`justify-content-between ${styles.containerfluid} px-5`}
        >
          <Row
            className={`justify-content-center justify-content-xl-between g-5 my-5 g-4 ${styles.buttons}`}
            xs={1}
            xl={3}
            xxl={3}
          >
            <Col className="text-center  d-grid place-items-center">
              <div>
                <StaticImage
                  src="../../images/icon.png"
                  alt="logo"
                  quality={100}
                />
              </div>
              {/* Title */}
              <h1 className={`${styles.title}  px-xxl-2 pt-4 text-capitalize`}>
                {intl.formatMessage({ id: "title" })}
              </h1>

              {/* <Link to="/booknow">
                <Button
                  className="py-3 px-5 text-white mt-3 mb-5"
                  variant="primary"
                >
                  {intl.formatMessage({ id: "learnMore" })}
                </Button>
              </Link> */}
              <Tags />
            </Col>

            <Col className="order-xl-first px-0 px-xl-5">
              {/* <div className="d-xl-none">
                <TechSupport />
              </div> */}

              <NewTimeTable />
            </Col>

            <Col className="px-0 px-xl-5">
              <div className="d-none d-xl-block">{/* <TechSupport /> */}</div>
              <AfterHours />
              <WhyKtmgNew />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Main
