import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Button, Col } from "react-bootstrap"
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa"
// import { TbVaccine } from "react-icons/tb"
import { GiBabyBottle } from "react-icons/gi"
// import {  } from "react-icons/"
import { AiOutlineClockCircle } from "react-icons/ai"

import * as styles from "../../styles/components/indexPage/tags.module.scss"
import { useIntl, navigate } from "gatsby-plugin-react-intl"

const Tags = () => {
  const intl = useIntl()

  return (
    <>
      <Col className="mx-auto">
        <Row>
          <Col
            xxl={6}
            xl={12}
            lg={6}
            sm={12}
            className={`${styles.social1} my-3 mb-2`}
            onClick={() => navigate(`/signature-services/free-vaccines`)}
          >
            <div className={`${styles.item} d-flex`}>
              <div className={styles.tag}>
                <StaticImage
                  alt="logo"
                  objectFit="cover"
                  src="../../images/tags/tag-1.png"
                  // width={80}
                  className={styles.img}
                />
              </div>
              <span>
                <h4> {intl.formatMessage({ id: "freeVaccinations" })}</h4>
                <h6>{intl.formatMessage({ id: "freeVaccinationsText" })}</h6>
              </span>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={12}
            lg={6}
            sm={12}
            className={`${styles.social2} my-3 mb-2`}
            onClick={() => navigate(`/signature-services/expectant-parents`)}
          >
            <div className={`${styles.item} d-flex`}>
              <div className={styles.tag}>
                <GiBabyBottle />
              </div>
              <span>
                <h4>{intl.formatMessage({ id: "baby" })}</h4>
                <h6>{intl.formatMessage({ id: "babyText" })}</h6>
              </span>
            </div>
          </Col>
          <Col
            xxl={7}
            xl={12}
            lg={6}
            sm={12}
            className={`${styles.social3} my-3 mb-2 text-center mx-auto`}
            onClick={() => navigate(`/insurance`)}
          >
            <div className={`${styles.item} d-flex`}>
              <div className={styles.tag}>
                <StaticImage
                  alt="logo"
                  objectFit="cover"
                  src="../../images/tags/tag-3.png"
                  // width={80}
                  className={styles.img}
                />
              </div>
              <span>
                <h4>{intl.formatMessage({ id: "insuranceAccept" })}</h4>
                <h6>{intl.formatMessage({ id: "insuranceText" })}</h6>
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  )
}
export default Tags
