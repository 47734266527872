import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby-plugin-react-intl"

import Table from "react-bootstrap/Table"
import * as styles from "../../../styles/components/indexPage/resources/vaccineSchedule.module.scss"

const VaccineSchedule = () => {
  return (
    <>
      {/* <Table>
        <thead>
          <tr className={styles.lightyellow}>
            <th>2 Weeks</th>
            <th className={styles.darkyellow}>2 Months</th>
            <th>4 Months</th>
            <th className={styles.darkyellow}>6 Months</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.darkwhite}>Hepatitis B</td>
            <td>
              Whooping Cough, Diptheria, Meningitis-Hib, Rotavirus, Hepatitis B,
              Tetanus, Polio, Pneumococcus
            </td>
            <td className={styles.darkwhite}>
              Whooping Cough, Diptheria, Meningitis-Hib, Rotavirus, Tetanus,
              Polio, Pneumococcus
            </td>
            <td>Hepatitis B</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className={styles.lightyellow}>9 Months</th>
            <th className={styles.darkyellow}>14 Months</th>
            <th className={styles.lightyellow}>18 Months</th>
            <th className={styles.darkyellow}>36 Months</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.darkwhite}>
              Whooping Cough, Diptheria, Meningitis-Hib, Rotavirus, Hepatitis B,
              Tetanus, Polio, Pneumococcus
            </td>
            <td>
              Whooping Cough, Diptheria, Meningitis-Hib, Rotavirus, Tetanus,
              Polio, Pneumococcus
            </td>
            <td className={styles.darkwhite}>
              Hepatitis A, Lead Level, Anemia Test
            </td>
            <td>Blood Pressure & Vision Test</td>
          </tr>
        </tbody>
      </Table> */}

      <Container fluid>
        <Row className="g-0 text-center">
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.lightyellow}>
                <h4>
                  <strong>Newborn Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p className="pt-5 mt-auto align-middle">
                  No Vaccines Given (Hep B given at the hospital)
                </p>
              </div>
            </div>
          </Col>

          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.darkyellow}>
                <h4>
                  <strong>2 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Hep B, Dtap, Ipv, Hib or (Pentacel), Pcv, Rotavirus</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.lightyellow}>
                <h4>
                  <strong>4 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Dtap, Ipv, Hib or (Pentacel), Pcv, Rotavirus</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.darkyellow}>
                <h4>
                  <strong>6 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Hep B, Dtap, Ipv, Hib or (Pentacel), Pcv </p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.lightyellow}>
                <h4>
                  <strong>9 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>No Vaccines Given (unless they're behind on vaccine)</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.darkyellow}>
                <h4>
                  <strong>12 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Pcv, Hib, Mmr, Varivax</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.lightyellow}>
                <h4>
                  <strong>15 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Dtap</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.darkyellow}>
                <h4>
                  <strong>18 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Hep A</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.lightyellow}>
                <h4>
                  <strong>24 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Hep A 2nd dose</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.darkyellow}>
                <h4>
                  <strong>30 Months Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>No Vaccines Given (unless they're behind on vaccines)</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.lightyellow}>
                <h4>
                  <strong>3 Years Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>No Vaccines Given</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.darkyellow}>
                <h4>
                  <strong>4 Years Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Dtap, Ipv, Mmr, Varivax</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.lightyellow}>
                <h4>
                  <strong>5 Years - 10 Years Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>No Vaccines Given (unless they're behind on vaccines)</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.darkyellow}>
                <h4>
                  <strong>11 Years - 12 Years Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Dtap, Mcv, Hpv</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.lightyellow}>
                <h4>
                  <strong>13 Years - 15 Years Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>No Vaccine Given (unless they're behind on vaccines)</p>
              </div>
            </div>
          </Col>
          <Col md={3} className="border">
            <div className={styles.item}>
              <div className={styles.darkyellow}>
                <h4>
                  <strong>16 Years - 18 Years Visit</strong>
                </h4>
              </div>
              <div className={`${styles.text}`}>
                <p>Mcv, Men B</p>
              </div>
            </div>
          </Col>
        </Row>
        <Link to="/signature-services/free-vaccines">
          <p className="mt-3" style={{ color: "#E5326E" }}>
            <strong>
              COVID-19 Vaccine Available For Children 6 Months and Up
            </strong>
          </p>
        </Link>
      </Container>
    </>
  )
}

export default VaccineSchedule
