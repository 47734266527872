import React from "react"
import { FaSearch, FaRegCheckCircle } from "react-icons/fa"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import * as styles from "../../styles/components/newtimetable.module.scss"

const WhyKtmgNew = () => {
  const intl = useIntl()
  return (
    <div>
      <Row className={`${styles.thead3} mt-5`}>
        <Row className={`text-center ms-2 pt-4 pb-3 px-0 px-xl-3`}>
          <h2 className={`mt-3 mb-2 text-capitalize`}>
            <strong>{intl.formatMessage({ id: "whyKTMG" })}</strong>
          </h2>
        </Row>
        <div colSpan={3} className={styles.item2}>
          <Row xs={1} lg={1} style={{ width: "100%" }} className="mx-auto ms-3">
            <Col md={12}>
              <p className="mb-3 h4 text-dark d-flex">
                <div>
                  <FaRegCheckCircle
                    color="green"
                    className="small me-4"
                    styles={{ width: "10px" }}
                  />
                </div>
                <Link to={"/providers"}>
                  <strong>{intl.formatMessage({ id: "point1" })}</strong>
                </Link>
              </p>
              <p className="mb-3 h4 text-dark d-flex">
                <div>
                  <FaRegCheckCircle
                    color="green"
                    className="small me-4"
                    styles={{ width: "10px" }}
                  />
                </div>
                <Link to={"/signature-services/same-day-appointments"}>
                  <strong>{intl.formatMessage({ id: "point2" })}</strong>
                </Link>
              </p>
              <p className="mb-3 h4 text-dark d-flex">
                <div>
                  <FaRegCheckCircle
                    color="green"
                    className="small me-4"
                    styles={{ width: "10px" }}
                  />
                </div>
                <Link to={"/locations"}>
                  <strong>{intl.formatMessage({ id: "point3" })}</strong>
                </Link>
              </p>
              <p className="mb-3 h4 text-dark d-flex">
                <div>
                  <FaRegCheckCircle
                    color="green"
                    className="small me-4"
                    styles={{ width: "10px" }}
                  />
                </div>
                <Link to={"/providers"}>
                  <strong>{intl.formatMessage({ id: "point4" })}</strong>
                </Link>
              </p>
              <p className="mb-3 h4 text-dark d-flex">
                <div>
                  <FaRegCheckCircle
                    color="green"
                    className="small me-4"
                    styles={{ width: "10px" }}
                  />
                </div>
                <Link to={"/blog/commercial-discount"}>
                  <strong>{intl.formatMessage({ id: "point5" })}</strong>
                </Link>
              </p>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default WhyKtmgNew

{
  /* <h2 className={`mt-3 mb-2 ${styles.subtitle} text-capitalize`}>
                {intl.formatMessage({ id: "whyKTMG" })}
              </h2>
              <Divider />

              <ul className={styles.list}>
                <Link to={"/providers"}>
                  <li>{intl.formatMessage({ id: "point1" })}</li>
                </Link>

                <Link to={"/signature-services/same-day-appointments"}>
                  <li>{intl.formatMessage({ id: "point2" })}</li>
                </Link>

                <a href="https://www.serendibhealthways.com" target="_blank">
                  <li>{intl.formatMessage({ id: "point3" })}</li>
                </a>
                <Link to={"/service/telehealth"}>
                  <li>{intl.formatMessage({ id: "point4" })}</li>
                </Link>

                <Link to={"/blog/commercial-discount"}>
                  <li>{intl.formatMessage({ id: "point5" })}</li>
                </Link>
              </ul> */
}
