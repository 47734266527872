import React, { useState } from "react"
import { Container, Form, Button } from "react-bootstrap"
import { navigate } from "gatsby-plugin-react-intl"
import * as styles from "../../styles/components/indexPage/mail.module.scss"
import { useIntl } from "gatsby-plugin-react-intl"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Mail = () => {
  const intl = useIntl()

  const [email, setEmail] = useState("")

  const handleSubmit = e => {
    e.preventDefault()

    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "subscribe",
        email,
      }),
    })
      .then(() => {
        navigate(form.getAttribute("action"))
      })
      .catch(error => console.log(error))
  }

  return (
    <div style={{ backgroundColor: "#EBB61F" }} className="pb-5">
      <Container className="py-5 text-center">
        <h1 className="fw-bold pt-5 text-capitalize">
          {intl.formatMessage({ id: "mailingList" })}
        </h1>
        <div className="d-flex justify-content-center align-item-center">
          <p className="w-xl-50 pt-3">
            {intl.formatMessage({ id: "mailingListDesc" })}
          </p>
        </div>
        <Form
          className={`py-4`}
          name="subscribe"
          method="post"
          data-netlify="true"
          action="/success"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <div className="d-flex justify-content-center">
            <Form.Control
              className={` ${styles.textInput} py-3 ps-3`}
              type="email"
              placeholder="Email Address"
              value={email}
              name="email"
              onChange={e => setEmail(e.target.value)}
              required
            />
            <Button
              type="submit"
              className={` ${styles.submitBtn} py-3 px-4 text-white fw-bold`}
            >
              Sign Up
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  )
}

export default Mail
