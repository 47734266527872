import React from "react"
import { Container, Row, Col, Button, Card } from "react-bootstrap"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-react-intl"

import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import Carousel from "react-multi-carousel"

import * as styles from "../../styles/components/indexPage/ourProviders.module.scss"
import { useIntl } from "gatsby-plugin-react-intl"

// React Multi Carousel Breakpoints
const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const OurProviders = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulDoctor(sort: { fields: updatedAt }) {
        edges {
          node {
            name
            specialization
            slug
            image {
              gatsbyImageData(width: 150, height: 150, quality: 100)
            }
          }
        }
      }
    }
  `)

  const intl = useIntl()

  return (
    <>
      <Container className="text-center mt-5 pt-5">
        <h1 className={styles.title}>
          {intl.formatMessage({ id: "providers" })}
        </h1>
        <p> {intl.formatMessage({ id: "providersDesc" })}</p>
      </Container>
      <Container className="mb-5 pb-5">
        <Row className="g-5">
          <Carousel
            sliderClass="align-items-center"
            autoPlay={false}
            autoPlaySpeed={5000}
            draggable={true}
            responsive={responsive}
            infinite={true}
            keyBoardControl={true}
            swipeable={true}
          >
            {data.allContentfulDoctor.edges.map(edge => {
              const { name, image, specialization, slug } = edge.node
              return (
                <Col className={styles.doctor}>
                  <div className="p-5">
                    {edge.node.image ? (
                      <GatsbyImage image={image.gatsbyImageData} alt="Doctor" />
                    ) : (
                      <StaticImage
                        src="../../images/placeholder.png"
                        alt="placeholder"
                        height={150}
                        width={150}
                      />
                    )}
                    <h4 className={`mt-5 mb-4 ${styles.doctorTitle}`}>
                      {name}
                    </h4>
                    {specialization && <p>{specialization}</p>}

                    <Link to={`/providers/${slug}`}>
                      <Button className={`${styles.btn} mt-5 p-3 p-md-auto`}>
                        {intl.formatMessage({ id: "readBio" })}
                      </Button>
                    </Link>
                  </div>
                </Col>
              )
            })}
          </Carousel>
          <div className="text-center">
            <Link to="/providers">
              <Button>{intl.formatMessage({ id: "viewProviders" })}</Button>
            </Link>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default OurProviders
