import React from "react"
import { Container, Row, Button, Col } from "react-bootstrap"
import * as styles from "../../styles/components/indexPage/why-ktmg.module.scss"
import { useIntl } from "gatsby-plugin-react-intl"

// import Logo from "../../components/Logo"

import Logo from "../../images/logo-2.png"
import Image1 from "../../images/partners/cedars-sinai.png"
import Image2 from "../../images/partners/childrens.png"
import Image3 from "../../images/partners/dignity-health.jpg"
import Image4 from "../../images/partners/hca.png"
import Image5 from "../../images/partners/huntington-hospital.png"
import Image6 from "../../images/partners/methodist.png"
import Image7 from "../../images/partners/providence.png"
import Image8 from "../../images/partners/valley-hospital.jpg"
import Image9 from "../../images/partners/ucla-health-logo.svg"

const WhyKTMG = () => {
  const intl = useIntl()
  return (
    <>
      <Container className={styles.main}>
        <Row className="g-3">
          <Col md={12} className="mb-5 text-center">
            <h1
              className="h3 mb-4 text-uppercase fw-bold"
              style={{ color: "#2D3388" }}
            >
              {intl.formatMessage({ id: "deliveringBaby" })}
            </h1>
            <p className="mb-5 px-5">
              {intl.formatMessage({ id: "deliveringBabyDesc" })}
            </p>
            <Row className="gy-5" xs={2}>
              <Col md={4} className="position-relative">
                <img
                  src={Image2}
                  alt="image-2"
                  width={100}
                  className="position-absolute top-50 start-50 translate-middle"
                />
              </Col>
              <Col md={4}>
                <img src={Image1} alt="image-1" width={100} />
              </Col>
              <Col md={4} className="position-relative">
                <img
                  src={Image9}
                  alt="image-9"
                  width={100}
                  className="position-absolute top-50 start-50 translate-middle"
                />
              </Col>
              <Col md={4}>
                <img src={Image3} alt="image-3" width={100} />
              </Col>
              <Col md={4}>
                <img src={Image4} alt="image-4" width={100} />
              </Col>
              <Col md={4}>
                <img src={Image5} alt="image-5" width={100} />
              </Col>
              <Col md={4}>
                <img src={Image6} alt="image-6" width={100} />
              </Col>
              <Col md={4}>
                <img src={Image7} alt="image-7" width={100} />
              </Col>
              <Col md={4}>
                <img src={Image8} alt="image-8" width={50} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default WhyKTMG
