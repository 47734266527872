import React, { useEffect, useState } from "react"

import { Container, Col, Row } from "react-bootstrap"
import reviews from "../Testimonials/content"
import Carousel from "react-multi-carousel"
import { GoStar } from "react-icons/go"
import axios from "axios"

import * as styles from "../../styles/components/indexPage/clientSay.module.scss"
import { useIntl } from "gatsby-plugin-react-intl"

// React Multi Carousel Breakpoints
const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const ClientSay = () => {
  const intl = useIntl()

  const [reviews, setReviews] = useState(null)
  const [positiveReviews, setPositiveReviews] = useState(null)
  const [fiveStarReviews, setFiveStarReviews] = useState(null)

  useEffect(() => {
    axios
      .post(
        "https://api.birdeye.com/resources/v1/review/businessId/165634118873631?&count=1000&api_key=CWFOSwxffcX6I2IgH1WvF9aWvB1hmauC",
        {}
      )
      .then(response => {
        setReviews(response.data)
        return response.data.filter(review => {
          return review.rating > 3 && review.sentimentType === "POSITIVE"
        })
      })
      .then(posReviews => {
        setPositiveReviews(
          posReviews.filter(function (el) {
            // Remove duplicate items
            if (!this[el.comments]) {
              this[el.comments] = true
              return true
            }
            return false
          }, Object.create(null))
        )
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    setFiveStarReviews(
      reviews?.filter(review => {
        return review.rating === 5
      }).length
    )
  }, [reviews])
  return (
    <>
      <Container className="text-center py-5 my-5">
        <h1 className="fw-bold pt-5 text-capitalize">
          {intl.formatMessage({ id: "customersSay" })}
        </h1>

        {fiveStarReviews && (
          <>
            <p className={`${styles.rating}`}>
              {intl.formatMessage({ id: "wehave" })}{" "}
              <span style={{ color: "#e5326e" }} className="h1 mx-2">
                <strong>{fiveStarReviews}</strong>
              </span>{" "}
              {intl.formatMessage({ id: "5star" })}
            </p>
            {
              // Loop Star 5 times
              Array(5)
                .fill(0)
                .map((_, i) => (
                  <span className="h3" key={i}>
                    <GoStar color="gold" />
                  </span>
                ))
            }
          </>
        )}
      </Container>

      <Container fluid className="mb-5 pb-5">
        <Row>
          {positiveReviews && (
            <Carousel
              sliderClass="align-items-center"
              autoPlay={false}
              autoPlaySpeed={5000}
              draggable={true}
              responsive={responsive}
              infinite={true}
              keyBoardControl={true}
              swipeable={true}
            >
              {positiveReviews?.map((review, i) => (
                <Col key={i} className={``}>
                  <div className={styles.review}>
                    {/* <ImQuotesRight className="h1 mb-5" color="#0dbceb" /> */}
                    <p className={` h3 p-0`}>
                      <strong>{`${review.reviewer.firstName} ${review.reviewer.lastName}`}</strong>
                    </p>
                    <div className="d-flex justify-content-center">
                      {
                        // Loop Star 5 times
                        Array(review.rating)
                          .fill(0)
                          .map((_, i) => (
                            <span className="h3" key={i}>
                              <GoStar color="gold" />
                            </span>
                          ))
                      }
                    </div>
                    {/* <p>{review.review.slice(0, 200)}</p> */}
                    <p className={`${styles.text}`}>{review.comments}</p>
                  </div>
                </Col>
              ))}
            </Carousel>
          )}
        </Row>
      </Container>
    </>
  )
}

export default ClientSay
