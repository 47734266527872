import React from "react"
import { Container, Row, Button, Col, Nav, Tab } from "react-bootstrap"

import * as styles from "../../styles/components/indexPage/parentResources.module.scss"

import BlueBarTitle from "../../components/IndexPage/blueBarTitle"
import VideoResources from "../IndexPage/resources/videoResources"
import ResourcesCarousel from "../Resources/resources-carousel"

// import Videos from "../Resources/videos"
import VaccineSchedule from "./resources/vaccineSchedule"
import { useIntl, Link } from "gatsby-plugin-react-intl"

const ParentResources = () => {
  const intl = useIntl()

  return (
    <>
      <BlueBarTitle
        title={intl.formatMessage({ id: "parentResources" })}
        sup={intl.formatMessage({ id: "parentResourcesDesc" })}
      />
      <div className={` ${styles.main}`}>
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row xs={1} sm={1} lg={2}>
              <Col xs={12} sm={12} lg={3} className="text-center text-lg-start">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item className={styles.navbutton}>
                    <Nav.Link eventKey="first">
                      <p className="py-lg-3 ps-3 mb-0">
                        {intl.formatMessage({ id: "vaccineSchedule" })}
                      </p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className={styles.navbutton}>
                    <Nav.Link eventKey="second">
                      <p className="py-lg-3 ps-3 mb-0">
                        {intl.formatMessage({ id: "videolibrary" })}
                      </p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className={styles.navbutton}>
                    <Nav.Link eventKey="third">
                      <p className="py-lg-3 ps-3 mb-0">Downloads</p>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className={styles.navbutton}>
                    <Nav.Link eventKey="therd">
                      <p className="py-lg-3 ps-3 mb-0">Well-Visit Schedule</p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navbutton}>
                    <Nav.Link eventKey="fourth">
                      <p className="py-lg-3 ps-3 mb-0">
                        Development Milestones
                      </p>
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>

                <Link to="/locations">
                  <Button
                    variant="book-now-pink"
                    size="lg"
                    className="py-3 px-5 mt-5 d-none d-lg-block"
                  >
                    {intl.formatMessage({ id: "bookAppointment" })}
                  </Button>
                </Link>
              </Col>
              <Col xs={12} sm={12} lg={9} className="mt-4 mt-lg-0">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <VaccineSchedule />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    {/* <Videos /> */}
                    <VideoResources />
                  </Tab.Pane>
                  {/* <Resource Carousel /> */}
                  <Tab.Pane eventKey="third">
                    <ResourcesCarousel />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </>
  )
}

export default ParentResources
