import React from "react"
import { FaSearch, FaRegCheckCircle } from "react-icons/fa"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import * as styles from "../styles/components/newtimetable.module.scss"

const AfterHours = () => {
  const highlights = [
    {
      text: "Less Waiting Time",
    },
    {
      text: "Pediatric care for Children 0-21",
    },
    {
      text: "We Accept All Insurances",
    },
    {
      text: "Hospital ER",
    },
    {
      text: "Immediate Booking Confirmation",
    },
  ]
  return (
    <div>
      {/* <a href="https://pediatricafterhour.com/" className={styles.link}> */}
      <Col md={12} xl={12}>
        <Row className={styles.row}>
          <Row
            className={`${styles.thead2} d-flex justify-content-between align-items-center pt-4 pb-3 px-0 px-xl-3`}
          >
            <Col
              xl={8}
              className="mb-xl-0 text-center text-xl-start align-items-center"
            >
              <h1 className="mb-0">
                <strong>Urgent Care</strong>
              </h1>
              <p>Visit a doctor immediately</p>
            </Col>

            <Col xl={4} className="mb-xl-0 text-center">
              <a href="https://pediatricafterhour.com/" className={styles.link}>
                <button className="btn-book-appt">BOOK APPT</button>
              </a>
            </Col>
          </Row>
          <div colSpan={3} className={styles.item2}>
            <Row xs={1} lg={1} style={{ width: "100%" }} className="mx-auto">
              {highlights.map((node, idx) => {
                return (
                  <Col key={idx} className={styles.item}>
                    <Row
                      className={`px-3 py-1 gy-md-0 text-start d-flex justify-content-center align-items-center ${
                        styles.tableRow
                      }
                    ${
                      idx === 1 ||
                      idx === 3 ||
                      idx === 5 ||
                      idx === 7 ||
                      idx === 9 ||
                      idx === 11 ||
                      idx === 13 ||
                      idx === 15 ||
                      idx === 17 ||
                      idx === 19 ||
                      idx === 21 ||
                      idx === 23 ||
                      idx === 25
                        ? styles.dark
                        : ""
                    }
                    ${idx % 2 !== 0 ? styles.darkMobile : ""} `}
                    >
                      <Col md={12}>
                        <p className="mb-0 h4 text-dark">
                          <FaRegCheckCircle
                            color="green"
                            className="small me-4"
                          />
                          <strong>{node.text}</strong>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </div>
        </Row>
      </Col>
    </div>
  )
}

export default AfterHours
