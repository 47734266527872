import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import * as styles from "../../styles/components/Signature/index-small.module.scss"
import { Link } from "gatsby-plugin-react-intl"
import Service from "./signature-service-small"
import { useIntl } from "gatsby-plugin-react-intl"

// const [zipCode, setZipCode] = useState("")
// const locationArray = []

// useEffect(() => {
// // Find coordinates with zipcode
// const coordinates = () => {
//   return {
//     lat: "dfsf",
//     lng: "fdsfds"
//   }
// }

// locationArray.sort(location => {
//   return (location.node.location.lat - coordinates.lat + location.node.location.lng - coordinates.lng)
// })

// }, zipCode)

// <form>
//   <input  value={zipcode} onChange={() => {setZipCode(e.target.value)}} />
// </form>

const SignatureServices = () => {
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      allContentfulSignatureServices {
        edges {
          node {
            description {
              raw
            }
            jsonIcon {
              file {
                url
              }
            }
            slug
            title
            specialMonitoring
          }
        }
      }
    }
  `)

  const services = data.allContentfulSignatureServices.edges
  return (
    <>
      <h1 className={`mb-2 mt-4 text-center ${styles.title}`}>
        Signature Services
      </h1>
      <Container
        className={`d-flex align-items-end justify-content-center mb-4 p-0`}
      >
        <Row className="g-3">
          {services
            .sort((a, b) => {
              return a.node.slug > b.node.slug ? 1 : -1
            })
            .map((service, idx) => {
              console.log(service.node.title)
              return (
                <Col key={idx}>
                  <Link to={`/signature-services/${service.node.slug}`}>
                    <Button
                      variant="light"
                      style={{
                        width: "100%",
                        height: "100%",
                        boxShadow: "0px 3px 6px #00000029",
                        background: "white",
                      }}
                      className="d-flex align-items-center justify-content-center flex-column"
                    >
                      <Service animationData={service} />
                      <h3 className="h5 mb-0 p text-capitalize">
                        <strong>
                          {intl.formatMessage({ id: service.node.title })}
                        </strong>
                      </h3>
                    </Button>
                  </Link>
                </Col>
              )
            })}
        </Row>
      </Container>

      {/* <div className={`${styles.container}`}>
        <div className={`${styles.index}`}>
          <h4
            className={`text-white text-capitalize text-center font-weight-bold ${styles.title}`}
          >
            <strong>{intl.formatMessage({ id: "signatureServices" })}</strong>{" "}
          </h4>
        </div>
        <div className={`${styles.index2}`}>
          <Container
            className={`d-flex align-items-end justify-content-center mb-4 p-4`}
          >
            <Row xs={2} md={3} className="g-3 justify-content-center">
              {services
                .sort((a, b) => {
                  return a.node.slug > b.node.slug ? 1 : -1
                })
                .map((service, idx) => {
                  return (
                    <Col key={idx}>
                      <Link to={`/signature-services/${service.node.slug}`}>
                        <Button
                          variant="light"
                          style={{
                            width: "100%",
                            height: "100%",
                            boxShadow: "0px 3px 6px #00000029",
                            background: "white",
                            padding: "30px",
                          }}
                          className="d-flex align-items-center justify-content-center flex-column"
                        >
                          <Service animationData={service} />
                          <h3 className="h5 mb-0 p">
                            <strong>
                              {intl.formatMessage({ id: service.node.title })}
                            </strong>
                          </h3>
                        </Button>
                      </Link>
                    </Col>
                  )
                })}
            </Row>
          </Container>
        </div>
      </div> */}
    </>
  )
}

export default SignatureServices
