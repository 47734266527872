import React from "react"
import * as styles from "../../styles/components/indexPage/blueBarTitle.module.scss"

export default function BlueBarTitle({ title, sup }) {
  return (
    <div className={styles.bluebar}>
      <h1 className="text-capitalize">{title}</h1>
      <p className={`text-capitalize`}>{sup}</p>
      <div className={styles.line} />
    </div>
  )
}
