import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FaSearch, FaRegCheckCircle } from "react-icons/fa"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import { format, setDate } from "date-fns"
import Form from "react-bootstrap/Form"
import CalendlyModal from "./calendlyModal"

//styles
import { Container, Row, Col, Button } from "react-bootstrap"
import * as styles from "../styles/components/newtimetable.module.scss"

const NewTimeTable = () => {
  const [searchTerm, setSearchTerm] = useState("")
  // const [comingSoonLocations, setComingSoonLocations] = useState([])

  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      allContentfulLocation {
        edges {
          node {
            slug
            coordinates
            location
            officeHours
            telehealth
            urgentCareHours
            afterhourLocation
            appointmentLink
            address {
              address
            }
          }
        }
      }
    }
  `)

  const [day, setDay] = useState("")

  useEffect(() => {
    setDay(format(new Date(), "eeee"))
  }, [])

  const sortedData = data.allContentfulLocation.edges
    .sort((a, b) => {
      if (a.node.location < b.node.location) {
        return 1
      }
      if (a.node.location > b.node.location) {
        return -1
      }
    })

    .sort((a, b) => {
      // if node.officeHours[day] exists return  b - a, else a - b
      if (
        !a.node.officeHours?.find(hour => {
          return hour.includes(day)
        }) &&
        b.node.officeHours?.find(hour => {
          return hour.includes(day)
        })
      ) {
        return 1
      } else {
        return -1
      }
    })
    .filter(location => {
      if (searchTerm && isNaN(searchTerm)) {
        return location.node.location.toLowerCase().includes(searchTerm)
      } else {
        return location
      }
    })
    .sort((a, b) => {
      if (!searchTerm || searchTerm.length !== 5 || isNaN(searchTerm)) {
        return 1
      } else {
        const address1 = a.node.address.address.split(" ")
        const zipCode1 = address1[address1.length - 1]
        const distance1 = Math.abs(zipCode1 - searchTerm)

        const address2 = b.node.address.address.split(" ")
        const zipCode2 = address2[address2.length - 1]
        const distance2 = Math.abs(zipCode2 - searchTerm)

        return -1 * (distance2 - distance1)
      }
    })

  return (
    <>
      <Container className="mt-4  mx-auto px-0">
        {/* Portal & Pay Button */}
        <div className="d-flex justify-content-center gap-2 mb-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.staxpayments.com/#/pay/Kids--Teens-Medical-Group---777-TRUMA-182dc7ffcc7b?l=eyJ0b3RhbCI6bnVsbCwibWVtbyI6bnVsbCwic3VjY2Vzc1JlZGlyZWN0IjoiaHR0cHM6Ly93d3cua3Rkb2N0b3IuY29tLyIsImZyZXF1ZW5jeSI6bnVsbCwicnJ1bGUiOm51bGwsImlzVG90YWxFZGl0YWJsZSI6ZmFsc2UsInRva2VuaXplT25seSI6ZmFsc2UsImFyZUNucERpZ2l0YWxXYWxsZXRQYXltZW50c0FsbG93ZWQiOmZhbHNlLCJhcmVCYW5rUGF5bWVudHNBbGxvd2VkIjpmYWxzZSwiYXJlQ3JlZGl0Q2FyZHNQYXltZW50c0FsbG93ZWQiOnRydWV9"
            data-reactroot=""
            className=" w-100"
          >
            <Button className="py-3 px-5 text-white" variant="primary w-100">
              Pay Now
            </Button>
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mycw178.ecwcloud.com/portal23441/jsp/100mp/login_otp.jsp"
            data-reactroot=""
            className=" w-100"
          >
            <Button className="py-3 px-5 text-white" variant="primary w-100">
              Patient Portal
            </Button>
          </a>
        </div>
        <Col md={12} xl={12}>
          <Row className={styles.row}>
            <Col className="p-0">
              <div className={`${styles.table}`}>
                <Row
                  className={`${styles.thead} py-4 px-0 px-xl-3 align-items-center d-flex justify-content-between `}
                >
                  <Col
                    xl={8}
                    className="mb-3 mb-xl-0 text-center text-xl-start"
                  >
                    <h1 className="mb-0">
                      <strong>
                        {intl.formatMessage({ id: "timeTableTitle" })}
                      </strong>
                    </h1>
                    <p className="mb-0 ">
                      {intl.formatMessage({ id: "timeTableDesc" })}
                    </p>
                  </Col>

                  <Col
                    xl={4}
                    className="d-flex align-items-center justify-content-center justify-content-lg-end"
                  >
                    <Link to="/booknow">
                      <button className="btn-book-appt mx-3">
                        {intl.formatMessage({ id: "timeTableBtn" })}
                      </button>
                    </Link>
                    {/* <Form.Select
                      className={styles.select}
                      aria-label="Default select example"
                      onChange={e => {
                        setDay(e.target.value)
                      }}
                      value={day}
                    >
                      <option value="Monday">
                        {intl.formatMessage({ id: "Mon" })}
                      </option>
                      <option value="Tuesday">
                        {intl.formatMessage({ id: "Tue" })}
                      </option>
                      <option value="Wednesday">
                        {intl.formatMessage({ id: "Wed" })}
                      </option>
                      <option value="Thursday">
                        {intl.formatMessage({ id: "Thu" })}
                      </option>
                      <option value="Friday">
                        {intl.formatMessage({ id: "Fri" })}
                      </option>
                      <option value="Saturday">
                        {intl.formatMessage({ id: "Sat" })}
                      </option>
                      <option value="Sunday">
                        {intl.formatMessage({ id: "Sun" })}
                      </option>
                    </Form.Select> */}
                  </Col>
                  {/* <Col sm={12} className="d-lg-none mt-3 text-center">
                    <Link to="/locations">
                      <Button variant="book-now-dark" className="w-100">
                        Book Now
                      </Button>
                    </Link>
                  </Col> */}
                </Row>

                <div colSpan={3} className={styles.item2}>
                  <Row
                    xs={1}
                    lg={1}
                    style={{ width: "100%" }}
                    className="mx-auto"
                  >
                    <Form
                      className={`mb-3`}
                      onSubmit={e => {
                        e.preventDefault()
                      }}
                    >
                      <Row>
                        <Col className="d-flex">
                          <Form.Group
                            className="mb-3 h-100 w-100"
                            controlId="fullName"
                          >
                            <Form.Control
                              placeholder="Search by City or Zip Code"
                              type="text"
                              value={searchTerm}
                              name="search"
                              onChange={e => setSearchTerm(e.target.value)}
                              required
                              className="h-100 w-100"
                            />
                          </Form.Group>
                          <Button variant="search" className="px-4 d-flex">
                            <FaSearch />
                          </Button>
                        </Col>
                      </Row>
                    </Form>

                    {sortedData.map(({ node }, idx) => {
                      const hours = node.officeHours?.find(hour => {
                        return hour.includes(day)
                      })
                      const telehealth = node.telehealth?.find(hour => {
                        return hour.includes(day)
                      })
                      return (
                        <Col key={idx} className={styles.item}>
                          <Row
                            xs={2}
                            md={2}
                            className={`px-3 py-1 gy-md-0 text-center text-lg-start d-flex justify-content-between align-items-center ${
                              styles.tableRow
                            }
                  ${
                    idx === 1 ||
                    idx === 3 ||
                    idx === 5 ||
                    idx === 7 ||
                    idx === 9 ||
                    idx === 11 ||
                    idx === 13 ||
                    idx === 15 ||
                    idx === 17 ||
                    idx === 19 ||
                    idx === 21 ||
                    idx === 23 ||
                    idx === 25
                      ? styles.dark
                      : ""
                  }
                  ${idx % 2 !== 0 ? styles.darkMobile : ""} `}
                          >
                            <Col md={4}>
                              <Link to={`/locations/${node.slug}`}>
                                <p className="mb-0 h4 text-dark">
                                  <strong>{node.location}</strong>
                                </p>
                              </Link>
                            </Col>
                            <Col
                              md={6}
                              className="d-none d-md-block text-center"
                            >
                              <Link
                                to={`/locations/${node.slug}`}
                                className="w-100"
                              >
                                <div className="mb-0 h5 w-100">
                                  <strong>
                                    {hours?.split(`${day}: `)[1] ? (
                                      // "Open \n" + hours.split(`${day}: `)[1]
                                      <h6 className="h5 mb-0">
                                        <strong>
                                          {intl.formatMessage({ id: "open" })}{" "}
                                          {hours.split(`${day}: `)[1]}
                                        </strong>
                                      </h6>
                                    ) : (
                                      <h6 className="h5 mb-0">
                                        <strong>
                                          {intl.formatMessage({
                                            id: "telehealth",
                                          })}{" "}
                                          {/* <br className="d-md-none" /> */}
                                          {telehealth?.split(`${day}: `)[1]}
                                        </strong>
                                      </h6>
                                    )}
                                  </strong>
                                </div>
                              </Link>
                            </Col>

                            <Col
                              md={2}
                              className={`d-flex justify-content-center justify-content-lg-end text-lg-end ${styles.hours}`}
                            >
                              <Link
                                to={`/locations/${node.slug}#appointment`}
                                className={styles.buttonBook}
                              >
                                Book
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  )
}

export default NewTimeTable
