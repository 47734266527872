import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Main from "../components/IndexPage/main"
import WhyKTMG from "../components/IndexPage/why-ktmg"
import ParentResources from "../components/IndexPage/parent-resources"
import OurProviders from "../components/IndexPage/ourProviders"
import NewBlog from "../components/IndexPage/newBlog"
import Mail from "../components/IndexPage/mail"
import ClientSay from "../components/IndexPage/clientSay"
import Notice from "../components/notice"
import SignatureServices from "../components/Signature/index-small"
import SerendibAndArticles from "../components/IndexPage/serendibAndArticles"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Kids and Teens Medical Group"
      description="Pediatrics specialists serving in the Greater Los Angeles area. Contact us at 818-244-9595 or visit us at one of our location across Los Angeles."
    />
    {/* <Notice /> */}
    <Main />
    <WhyKTMG />
    <ParentResources />
    <SignatureServices />
    <OurProviders />
    {/* <NewBlog /> */}
    <SerendibAndArticles />
    <Mail />
    <ClientSay />
    {/*<Hero />
    <Partners />
    <Component3 />
    <Information />
    <Vaccine />
    <Blog /> */}
  </Layout>
)

export default IndexPage
